import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import fieldNames from './field-name';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import SideBar from './side-bar/index';
import Button from '@material-ui/core/Button';
import logger from '../../utils/logger';
import RenderActions from './render-actions';
import images from '../../constants/images/index';
import { deleteUserComment as deleteUserCommentAPI } from '../comments/apis';
import { connect } from 'react-redux';
import { deleteUser, genstat } from '../users/apis';
import { ModeComment } from '@material-ui/icons';
import moment from 'moment';
import RenderTableCell from './render-table-cell';
import NoRecord from './no-record/index';
import { deleteVideo } from '../videos/apis';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const hideActionsFor = [
  'downloads',
  'allEnquiries',
  'nonAttendeesForActivity',
  'allUsersCallLogs',
  'connect',
  'connects',
  'qna_response',
  'video_viewed',
  'activity_response',
  'webinars',
  'call_logs',
  'video_comments',
  'video_comment',
  'liked_videos',
  'activityResponse',
  'callDetails',
  'webinarResponses',
  'qnaListing',
  'userWisePoints',
  'qnaResponses',
  'coinsLedger',
  'videoViewsList',
  'Users blocked from comments Enquery',
  'General Enquery',
  'Block Comment Enquery',
  'Block Peer Enquery',
  'Block Video Enquery',
  'nonViewersForVideo',
  'chatLogs',
  'user_points',
  'pairedUsers',
  'unpairedUsers',
  'pairingHistory',
  'pollQuestions',
  'call_logs_user_engagement',
  'chatLogs_user_engagement',
  'activity_response_user_engagement',
  'video_viewed',
  'webinar_points',
  'complaintsAgainstUser',
  'discountCouponsList',
  'question_points',
  'individualScoringData',
  'overallScoringData',
  'pairScoringData',
  'userWisePointsPeerDetails',
  'suspendedUsers',
  'leads',
];

const GenericTable = ({
  hideActions = false,
  data,
  requiredFor,
  setPageNumber,
  limit = 0,
  pageNumber = 0,
  addButtonTitle,
  addDataTemplate,
  updateProfileKey,
  showBlockPeer,
  loading,
  deleteUserComment,
  deleteUser,
  searchAgain,
  setSearchAgain,
  totalCount,
  refresher,
  setRefresher,
  acceptNewUser,
  rejectNewUser
}) => {

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  const [drawerData, setDrawerData] = useState({});
  const [itemIndex, setItemIndex] = useState();
  const toggleSideBar = (status, data, index = '') => {
    setDrawerData(data);
    setIsDrawerOpen(status);
    setItemIndex(index);
  };

  console.log(data, 'videosListvideosList');

  const classes = useStyles();
  let fields;
  fields = fieldNames[requiredFor];

  console.log(fields, "fields");

  const blockPeer = async (requiredFor, data) => {
    let userData = {};
    userData = await genstat(data._id);
    console.log(userData, data, 'cnsdbkjcbslkdcndslc');
    const { meta: userMeta, _id: userId } = data;
    const { chats: { channels = [] } = {} } = userData;
    const peerMeta = channels[1] && channels[1].buddy_detail?.meta;
    const peerId = channels[1] && channels[1].buddy_detail?._id;

    if (peerId && userId) {
      await updateProfileKey(peerId, 'meta', {
        ...peerMeta,
        isBlockedByPeer: requiredFor === 'block' ? true : false,
      });
      await updateProfileKey(userId, 'meta', {
        ...userMeta,
        haveBlockedPeer: requiredFor === 'block' ? true : false,
      });
    }
    //setSearchAgain(searchAgain + 1);
  };

  // Additional css
  const loadingCSS = {
    height: '20px',
    margin: '30px',
  };

  // To change the loading icon behavior
  const loadingTextCSS = { display: loading ? 'block' : 'none' };

  return (
    <div>
      {addButtonTitle && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingRight: 50,
            paddingLeft: 50,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => toggleSideBar(true, addDataTemplate, requiredFor)}
          >
            {addButtonTitle}
          </Button>
        </div>
      )}
      <div
        style={{
          height: 500,
          marginTop: 10,

          overflowY: 'auto',  // Keep vertical scrolling as auto
          marginRight: 20,
          marginLeft: 20,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.5)",
          borderRadius: "8px",
          backgroundColor: "#fff",
          whiteSpace: "nowrap", // Prevent wrapping of content
        }}
      >
        <TableContainer component={Paper} >
          <Table className={classes.table} aria-label="customized table">
            <TableHead style={{ cursor: 'pointer', postion: 'fixed' }}>
              <TableRow>
                <TableCell align="left">Sr. No.</TableCell>
                {fields['keys'].map((field, index) => (
                  <TableCell key={index} align="left">
                    {field.name}
                  </TableCell>
                ))}

                {hideActions || hideActionsFor.includes(requiredFor) ? null : (
                  <TableCell align="left">Actions</TableCell>
                )}
              </TableRow>
            </TableHead>


            <TableBody style={{

            }} >

              {data &&
                data?.map((row, index) => (
                  <TableRow key={row.index + row?.userId} >
                    <TableCell style={{}}>{requiredFor === "users" || "leads" ? ((pageNumber) * limit) + index + 1 : index + 1}</TableCell>
                    {fields['keys'].map((item, index) => {
                      // console.log(item)
                      return (
                        <RenderTableCell item={item} row={row} key={row.index + row?.userId + index + 'field'} />
                      )
                    })}

                    {hideActions ||
                      hideActionsFor.includes(requiredFor) ? null : (
                      <RenderActions
                        requiredFor={requiredFor}
                        row={row}
                        setPageNumber={setPageNumber}
                        blockPeer={blockPeer}
                        toggleSideBar={toggleSideBar}
                        showBlockPeer={row?.paired}
                        refresher={refresher}
                        setRefresher={setRefresher}
                        searchAgain={searchAgain}
                        setSearchAgain={setSearchAgain}
                        index={index}
                        approveUser={"dummy"}
                        rejectUser={"dummy123"}
                        acceptNewUser={acceptNewUser}
                        rejectNewUser={rejectNewUser}
                      />
                    )}

                    {fields?.details?.navigateTo && (
                      <TableCell style={{}}>
                        <Link
                          style={{ textDecoration: 'none' }}
                          to={{
                            pathname: `${fields?.details?.navigateTo}/${row._id}`,
                            navigationData: row, // your data array of objects
                          }}
                        >
                          {fields?.details?.navigateToCTA
                            ? fields?.details?.navigateToCTA
                            : 'View Details'}
                        </Link>
                        {fields?.details?.navigateToTwo && (
                          <>
                            <br />
                            <Link
                              style={{ textDecoration: 'none' }}
                              to={{
                                pathname: `${fields?.details?.navigateToTwo}/${row._id}`,
                                navigationData: row, // your data array of objects
                              }}
                            >
                              {fields?.details?.navigateToTwoCTA
                                ? fields?.details?.navigateToTwoCTA
                                : 'View Details'}
                            </Link>
                          </>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>

          </Table>
          <div
            style={{
              padding: '15px 40px 15px 40px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontSize: 14,
              color: 'grey',
            }}
          >
            <span>Displaying {data?.length} records</span>
            {totalCount ? <span>Total: {totalCount}</span> : null}
            {data?.length <= 0 && <span>Total: 0</span>}
          </div>
        </TableContainer>
      </div>
      {/* <div style={loadingCSS}>
        <span style={loadingTextCSS}>Loading...</span>
      </div> */}

      <SideBar
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        data={drawerData}
        setPageNumber={setPageNumber}
        setDrawerData={setDrawerData}
        toggleSideBar={toggleSideBar}
        requiredFor={requiredFor}
        searchAgain={searchAgain}
        setSearchAgain={setSearchAgain}
        refresher={refresher}
        setRefresher={setRefresher}
        index={itemIndex}
      />
    </div>
  );
};

const actions = {
  deleteUserComment: deleteUserCommentAPI,
};
export default connect(null, actions)(GenericTable);
