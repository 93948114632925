import React, { useState } from "react";
import axios from "axios";
import { Button, TextField, Select, MenuItem, Typography, Dialog, DialogTitle, DialogActions } from "@material-ui/core";

const EditContentModal = ({ contentId, onClose }) => {
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    // Fetch content by ID
    const fetchContent = async () => {
        try {
            const response = await axios.get(`https://genwescoring.thegenwe.com/scraper/getcontent/${contentId}`);
            setContent(response.data.data);
        } catch (error) {
            console.error("Error fetching content:", error);
        } finally {
            setLoading(false);
        }
    };

    // Update content (Publish or Save as Draft)
    const updateContent = async (saveAsDraft = false) => {
        try {
            setButtonLoading(true);
            await axios.put(
                `https://genwescoring.thegenwe.com/scraper/updatecontent/${contentId}`,
                {
                    category: content.category,
                    title: content.title,
                    description: content.description,
                    imageUrl: content.imageUrl,
                },
                {
                    params: saveAsDraft ? { save: true } : {},
                }
            );
            setButtonLoading(false);
            alert(saveAsDraft ? "Content saved as draft!" : "Content published successfully!");
            onClose();
        } catch (error) {
            setButtonLoading(false);
            console.error("Error updating content:", error);
        }
    };

    const onReject = async () => {
        try {
            setButtonLoading(true);
            await axios.put(`https://genwescoring.thegenwe.com/scraper/rejectcontent/${contentId}`);
            setButtonLoading(false);
            alert("Content rejected!");
            onClose();
        } catch (error) {
            setButtonLoading(false);
            console.error("Error rejecting content:", error);
        }
    };

    // Handle input changes
    const handleChange = (field, value) => {
        setContent((prevContent) => ({
            ...prevContent,
            [field]: value,
        }));
    };

    // Fetch content on mount
    React.useEffect(() => {
        fetchContent();
    }, []);

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    // Open confirmation dialog
    const handlePublishClick = () => {
        setConfirmDialogOpen(true);
    };

    // Confirm publishing
    const handleConfirmPublish = () => {
        setConfirmDialogOpen(false);
        updateContent(false); // Publish content
    };

    return (
        <div
            style={{
                display: "flex",
                borderRadius: 20,
                margin: "100px auto 50px auto",
                padding: 40,
                gap: 20,
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
        >
            <div style={{ flex: 1 }}>
                {content.type === "podcast" ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                        <audio controls style={{ width: "100%", borderRadius: "4px", margin: "1rem" }}>
                            <source src={content?.audioUrl} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                ) : (
                    <div
                        style={{
                            position: "relative",
                            paddingBottom: "56.25%",
                            height: 0,
                            overflow: "hidden",
                            borderRadius: "4px",
                            background: "#000",
                        }}
                    >
                        <iframe
                            src={`https://www.youtube.com/embed/${content.id}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="YouTube Video"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                            }}
                        />
                    </div>
                )}
            </div>

            <div style={{ flex: 1 }}>
                <Typography variant="h6" style={{ marginBottom: "1rem" }}>
                    Edit Content
                </Typography>
                <TextField
                    label={content.type === "podcast" ? "Podcast Title" : "Video Title"}
                    value={content.title}
                    onChange={(e) => handleChange("title", e.target.value)}
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: "1rem" }}
                />
                <TextField
                    label="Description"
                    value={content.description}
                    onChange={(e) => handleChange("description", e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    style={{ marginBottom: "1rem" }}
                />
                <Select
                    value={content.category}
                    onChange={(e) => handleChange("category", e.target.value)}
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "1rem" }}
                    disabled
                >
                    <MenuItem value="Mental Health">Mental Health</MenuItem>
                    <MenuItem value="Bullying">Bullying</MenuItem>
                    <MenuItem value="Academic Pressure">Academic Pressure</MenuItem>
                    <MenuItem value="Peer Pressure">Peer Pressure</MenuItem>
                    <MenuItem value="Discrimination">Discrimination</MenuItem>
                    <MenuItem value="Family">Family</MenuItem>
                    <MenuItem value="Testing">Testing</MenuItem>
                </Select>

                <div style={{ display: "flex", gap: "1rem" }}>
                    <Button
                        variant="contained"
                        style={{ backgroundColor: "#4caf50", color: "#fff" }}
                        onClick={handlePublishClick}
                    >
                        {buttonLoading ? "Loading..." : "Publish"}
                    </Button>

                    <Button
                        variant="contained"
                        onClick={() => updateContent(true)}
                        style={{ backgroundColor: "#2196f3", color: "#fff" }}
                    >
                        {buttonLoading ? "Loading..." : "Save as Draft"}
                    </Button>

                    <Button
                        variant="contained"
                        onClick={onReject}
                        style={{ backgroundColor: "#D64E4E", color: "#fff" }}
                    >
                        {buttonLoading ? "Loading..." : "Reject"}
                    </Button>

                    <Button
                        variant="contained"
                        onClick={onClose}
                        style={{ backgroundColor: "gray", color: "#fff" }}
                    >
                        Close
                    </Button>
                </div>
            </div>

            <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
                <div style={{ padding: 20 }}>
                    <DialogTitle>Confirm Publish</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setConfirmDialogOpen(false)} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmPublish} color="primary" autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
};

export default EditContentModal;
