import { TableCell } from '@material-ui/core';
import { Link } from 'react-router-dom';
import apiUrls from '../../apis/apis';
import { store } from '../../redux/store';

const fieldNames = {
  videos: {
    details: {
      navigateTo: '/videos/comments',
      navigateToCTA: 'View Comments',
      navigateToTwo: '/videos/video-views-list',
      navigateToTwoCTA: 'View Views',
    },
    keys: [
      {
        name: 'Thumbnail',
        keyName: 'thumbnail',
      },
      {
        name: 'ID',
        keyName: '_id',
      },
      {
        name: 'Title',
        keyName: 'title',
      },
      {
        name: 'Description',
        keyName: 'about',
      },
      {
        name: 'Likes',
        keyName: 'likes',
      },
      {
        name: 'Views',
        keyName: 'views',
      },
      {
        name: 'Comments',
        keyName: 'total_comments',
      },
    ],
  },
  videoViewsList: {
    details: {},
    keys: [
      {
        name: 'UserId',
        keyName: 'user_id',
      },
      {
        name: 'Points',
        keyName: 'points',
      },
      {
        name: 'Title',
        keyName: 'title',
      },
      { name: 'Viewed At', keyName: 'created_at', type: 'date' },
    ],
  },
  connects: {
    details: {
      navigateTo: '/connects',
    },
    keys: [
      {
        name: 'Thumbnail',
        keyName: 'thumbnail',
      },
      {
        name: 'ID',
        keyName: '_id',
      },
      {
        name: 'Title',
        keyName: 'title',
      },
      {
        name: 'Description',
        keyName: 'description',
      },
    ],
  },
  connect: {
    details: {
      navigateTo: '/activity-response',
    },
    keys: [
      {
        name: 'Thumbnail',
        keyName: 'icon',
      },
      {
        name: 'ID',
        keyName: '_id',
      },
      {
        name: 'Title',
        keyName: 'title',
      },
      {
        name: 'Type',
        keyName: 'type',
      },
    ],
  },
  newRegistrations: {
    // details:{
    //   navigateTo:"/user-request"
    // },
    keys: [
      // {
      //   name: 'Name',
      //   keyName: 'name',
      // },
      {
        name: 'Email ID',
        keyName: 'email',
      },
      {
        name: "Contact Number",
        keyName: 'phone_number',
      },
      {
        name: 'Video',
        keyName: 'video',
        type: "video"
      },
    ]
  },
  suspendedUsers: {
    keys: [
      {
        name: 'Name',
        keyName: 'name',
      },
      {
        name: 'Email ID',
        keyName: 'email',
      },
      {
        name: "Contact Number",
        keyName: 'phone_number',
      },
      {
        name: 'Suspended date',
        keyName: 'suspended_at_formatted',
      },
    ]
  },
  leads: {
    keys: [
      {
        name: 'Phone',
        keyName: 'phone_number',
      },
      {
        name: "Email",
        keyName: 'email'
      },
      {
        name: 'Created At',
        keyName: 'created_at_formatted',
      },
      // {
      //   name: "Contact Number",
      //   keyName: 'phone_number',
      // },
      // {
      //   name: 'Suspended date',
      //   keyName: 'suspended_at_formatted',
      // },
    ]
  },
  users: {
    details: {
      navigateTo: '/user/engagement',
    },
    keys: [
      {
        name: 'Thumbnail',
        keyName: 'avatar',
        type: 'base64',
      },
      {
        name: 'Name',
        keyName: 'name',
      },
      {
        name: 'Mobile',
        keyName: 'phone_number',
      },

      {
        name: "Email",
        keyName: "email"
      },

      {
        name: 'Sign Up',
        keyName: 'created_at',
        type: 'date',
      },
      {
        name: 'Gender',
        keyName: 'gender',
        type: 'peerId',
      },
      {
        name: 'Dob',
        keyName: 'dob',
        // type: 'onlyDate',
      },
      {
        name: "Verification Status",
        keyName: "verification_status"
      },
      {
        name: 'Paired',
        keyName: 'paired',
        type: 'bool',
      },
    ],
  },
  videoComments: {
    details: {},
    keys: [
      {
        name: 'Name',
        keyName: 'name',
      },
      {
        name: 'Comment',
        keyName: 'comment',
      },
      {
        name: 'Time',
        keyName: 'created_at',
        type: 'date',
      },
    ],
  },
  blockedVideoComments: {
    details: {},
    keys: [
      {
        name: 'Name',
        keyName: 'name',
      },
      {
        name: 'Comment',
        keyName: 'comment',
      },
      {
        name: 'Time',
        keyName: 'created_at',
        type: 'date',
      },
    ],
  },
  call_logs: {
    details: {},
    keys: [
      {
        name: 'User id',
        keyName: 'from_user_id',
      },
      {
        name: 'Name',
        keyName: 'from_user.name',
        type: 'nestedKey',
      },
      {
        name: 'Start Time',
        keyName: 'start_time',
        type: 'date',
      },
      {
        name: 'Duration(seconds)',
        keyName1: 'start_time',
        keyName2: 'end_time',
        type: 'duration',
      },
      {
        name: 'Peer id',
        keyName: 'to_user_id',
      },
      {
        name: 'Peer Name',
        keyName: 'to_user.name',
        type: 'nestedKey',
      },

      {
        name: 'Type',
        keyName: 'type',
      },
    ],
  },
  call_logs_user_engagement: {
    details: {},
    keys: [
      {
        name: 'User id',
        keyName: 'from_user_id',
      },
      {
        name: 'Name',
        keyName: 'from_user.name',
        type: 'nestedKey',
      },
      {
        name: 'School',
        keyName: 'from_user.school',
        type: 'nestedKey',
      },
      {
        name: 'Start Time',
        keyName: 'start_time',
        type: 'date',
      },
      {
        name: 'Duration(seconds)',
        keyName1: 'start_time',
        keyName2: 'end_time',
        type: 'duration',
      },
      {
        name: 'Peer id',
        keyName: 'to_user_id',
      },
      {
        name: 'Peer Name',
        keyName: 'to_user.name',
        type: 'nestedKey',
      },
      {
        name: 'Peer Group',
        keyName: 'to_user.school',
        type: 'nestedKey',
      },

      {
        name: 'Type',
        keyName: 'type',
      },
    ],
  },
  webinars: {
    details: {},
    keys: [
      {
        name: 'Registration Date',
        keyName: 'created_at',
        type: 'date',
      },
      {
        name: 'UserId',
        keyName: 'user_id',
      },
      {
        name: 'Webinar Id',
        keyName: 'webinar_id',
      },
    ],
  },
  activity_response: {
    details: {},
    keys: [
      {
        name: 'Title',
        keyName: 'title',
      },
      {
        name: 'UserId',
        keyName: 'user_id',
      },
      {
        name: 'Program Id',
        keyName: 'program_id',
      },
      {
        name: 'Program Id',
        keyName: 'program_id',
      },
      {
        name: 'Activity Id',
        keyName: 'activity_id',
      },
      {
        name: 'Completion Date',
        keyName: 'created_at',
        type: 'date',
      },
      {
        name: 'Points',
        keyName: 'points',
      },
    ],
  },
  activity_response_user_engagement: {
    details: {},
    keys: [
      {
        name: 'User id',
        keyName: 'user_obj._id',
        type: 'nestedKey',
      },
      {
        name: 'User Name',
        keyName: 'user_obj.name',
        type: 'nestedKey',
      },
      {
        name: 'Group',
        keyName: 'user_obj.school',
        type: 'nestedKey',
      },
      {
        name: 'Date',
        keyName: 'last_activity_date',
        type: 'date',
      },
      {
        name: 'Title',
        keyName: 'title',
      },
      {
        name: 'Type',
        type: 'custom',
        Component: ({ data }) => {
          return (
            <TableCell component="th" scope="row">
              {data['public'] ? 'Individual' : 'Peer'}
            </TableCell>
          );
        },
      },
      {
        name: 'Status',
        keyName: 'status',
      },
      {
        name: 'Points',
        keyName: 'coin_ledger_obj.total_point',
        type: 'nestedKey',
      },
    ],
  },
  video_comment: {
    details: {},
    keys: [
      {
        name: 'User Id',
        keyName: 'user_id',
      },
      {
        name: 'Video Id',
        keyName: 'video_id',
      },
      {
        name: 'Comment',
        keyName: 'comment',
      },
    ],
  },
  liked_videos: {
    details: {},
    keys: [
      {
        name: 'Title',
        keyName: 'title',
      },
      {
        name: 'Category',
        keyName: 'category',
      },
      {
        name: 'Blocked',
        keyName: 'blocked',
        type: 'bool',
      },
    ],
  },

  video_viewed: {
    details: {},
    keys: [
      {
        name: 'UserId',
        keyName: 'user_id',
      },
      {
        name: 'User name',
        keyName: 'user_obj.name',
        type: 'nestedKey',
      },
      {
        name: 'User Group',
        keyName: 'user_obj.school',
        type: 'nestedKey',
      },

      {
        name: 'Title',
        keyName: 'title',
      },
      {
        name: 'Video Id',
        keyName: 'video_id',
      },
      {
        name: 'Date',
        keyName: 'created_at',
        type: 'date',
      },
      {
        name: 'Points',
        keyName: 'points',
      },
    ],
  },
  qna_response: {
    details: {},
    keys: [
      {
        name: 'UserId',
        keyName: 'user_id',
      },

      {
        name: 'Question Id',
        keyName: 'q_id',
      },
      {
        name: 'Date',
        keyName: 'created_at',
        type: 'date',
      },
      {
        name: 'Response',
        keyName: 'response',
        type: 'array',
      },
    ],
  },
  activityResponse: {
    details: {},
    keys: [
      {
        name: 'UserId',
        keyName: '_id',
      },
      {
        name: 'Name',
        keyName: 'name',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Response',
        keyName: 'response',
        type: 'object',
      },
      {
        name: 'Paired',
        keyName: 'paired',
        type: 'bool',
      },
      {
        name: 'Buddy',
        keyName: 'buddy',
      },
    ],
  },
  allUsersCallLogs: {
    details: {},
    keys: [
      {
        name: 'UserId',
        keyName: '_id',
      },
      {
        name: 'Total Calls',
        keyName: 'count',
      },
      {
        name: 'Last call',
        keyName: 'last_call',
        type: 'date',
      },
    ],
  },
  individualScoringData: {
    details: {},
    keys: [
      {
        name: 'User ID',
        keyName: 'userId',
      },
      {
        name: 'User Name',
        keyName: 'userName',
      },
      {
        name: 'Category',
        keyName: 'category',
      },
      {
        name: 'Score',
        keyName: 'score',
      },
    ]
  },
  pairScoringData: {
    details: {},
    keys: [
      {
        name: 'User ID',
        keyName: 'userId',
      },
      {
        name: 'User Name',
        keyName: 'userName',
      },
      {
        name: 'Peer ID',
        keyName: 'peerId',
      },
      {
        name: 'Peer Name',
        keyName: 'peerName',
      },
      {
        name: 'Category',
        keyName: 'category',
      },
      {
        name: 'Score',
        keyName: 'score',
      },

    ]
  },
  overallScoringData: {
    details: {},
    keys: [
      {
        name: 'Category',
        keyName: 'category',
      },
      {
        name: 'Score',
        keyName: 'score',
      },
    ]
  },
  nonAttendeesForActivity: {
    details: {},
    keys: [
      {
        name: 'Name',
        keyName: 'name',
      },
      {
        name: 'UserId',
        keyName: '_id',
      },
      {
        name: 'Mobile',
        keyName: 'phone_number',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Paired',
        keyName: 'paired',
        type: 'bool',
      },
      {
        name: 'Buddy',
        keyName: 'buddy',
      },
    ],
  },
  nonViewersForVideo: {
    details: {},
    keys: [
      {
        name: 'Name',
        keyName: 'name',
      },
      {
        name: 'UserId',
        keyName: '_id',
      },
      {
        name: 'Mobile',
        keyName: 'phone_number',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Paired',
        keyName: 'paired',
        type: 'bool',
      },
      {
        name: 'Buddy',
        keyName: 'buddy',
      },
    ],
  },
  'General Enquery': {
    details: {},
    keys: [
      {
        name: 'UserId',
        keyName: 'phone_number',
      },

      {
        name: 'Name',
        keyName: 'name',
      },

      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Type',
        keyName: 'type',
      },
      {
        name: 'Message',
        keyName: 'message',
      },
      {
        name: 'Details',
        keyName: 'meta',
        type: 'object',
      },
    ],
  },
  'Users blocked from comments Enquery': {
    details: {},
    keys: [
      {
        name: 'UserId',
        keyName: 'phone_number',
      },

      {
        name: 'Name',
        keyName: 'name',
      },

      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Type',
        keyName: 'type',
      },
      {
        name: 'Message',
        keyName: 'message',
      },
      {
        name: 'Commentor UserId',
        keyName: 'meta.id',
        type: 'nestedKey',
      },
      {
        name: 'Comment',
        keyName: 'meta.text',
        type: 'nestedKey',
      },
      {
        name: 'VideoId',
        keyName: 'meta.videoId',
        type: 'nestedKey',
      },
    ],
  },
  'Block Comment Enquery': {
    details: {},
    keys: [
      {
        name: 'UserId',
        keyName: 'phone_number',
      },

      {
        name: 'Name',
        keyName: 'name',
      },

      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Type',
        keyName: 'type',
      },
      {
        name: 'Message',
        keyName: 'message',
      },
      {
        name: 'Commentor UserId',
        keyName: 'meta.id',
        type: 'nestedKey',
      },
      {
        name: 'VideoId',
        keyName: 'meta.videoId',
        type: 'nestedKey',
      },
      {
        name: 'Comment',
        keyName: 'meta.text',
        type: 'nestedKey',
      },
    ],
  },
  'Block Peer Enquery': {
    details: {},
    keys: [
      {
        name: 'UserId',
        keyName: 'phone_number',
      },

      {
        name: 'Name',
        keyName: 'name',
      },

      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Type',
        keyName: 'type',
      },
      {
        name: 'Message',
        keyName: 'message',
      },
      {
        name: 'Peer UserId',
        keyName: 'meta.peerId',
        type: 'nestedKey',
      },
    ],
  },
  'Block Video Enquery': {
    details: {},
    keys: [
      {
        name: 'UserId',
        keyName: 'phone_number',
      },

      {
        name: 'Name',
        keyName: 'name',
      },

      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Type',
        keyName: 'type',
      },
      {
        name: 'Message',
        keyName: 'message',
      },
      {
        name: 'VideoId',
        keyName: 'meta.id',
        type: 'nestedKey',
      },
      {
        name: 'Video Name',
        keyName: 'meta.name',
        type: 'nestedKey',
      },
    ],
  },
  downloads: {
    details: {},
    keys: [
      {
        name: 'Title',
        keyName: 'name',
      },
      {
        name: 'Download Link',
        keyName: 'link',
        type: 'link',
      },
    ],
  },
  callDetails: {
    details: {},
    keys: [
      {
        name: 'Video (in seconds)',
        keyName: 'total.video',
        type: 'nestedKey',
      },
      {
        name: 'Voice (in seconds)',
        keyName: 'total.voice',
        type: 'nestedKey',
      },
      {
        name: 'Total (in seconds)',
        keyName: 'total.overall',
        type: 'nestedKey',
      },
      {
        name: 'UserId 1',
        keyName: 'user_1.user',
        type: 'nestedKey',
      },
      {
        name: 'UserId 2',
        keyName: 'user_2.user',
        type: 'nestedKey',
      },
    ],
  },
  webinarResponses: {
    details: {},
    keys: [
      {
        name: 'Name',
        keyName: 'name',
      },
      {
        name: 'Phone Number',
        keyName: 'phone_number',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
    ],
  },
  coinsLedger: {
    details: {},
    keys: [
      {
        name: 'UserId',
        keyName: 'user_id',
      },
      {
        name: 'title',
        keyName: 'title',
      },
      {
        name: 'Points',
        keyName: 'points',
      },
      {
        name: 'Date',
        keyName: 'created_at',
        type: 'date',
      },
    ],
  },
  qnaListing: {
    details: {
      navigateTo: '/qna-responses',
    },
    keys: [
      {
        name: 'Thumbnail',
        keyName: 'icon_url',
      },
      {
        name: 'ID',
        keyName: '_id',
      },
      {
        name: 'Header',
        keyName: 'header',
      },
      {
        name: 'Title',
        keyName: 'title',
      },
      {
        name: 'Type',
        keyName: 'type',
      },
    ],
  },
  qnaResponses: {
    details: {},
    keys: [
      {
        name: 'UserId',
        keyName: 'user_id',
      },
      {
        name: 'Response',
        keyName: 'response',
        type: 'array',
      },
      {
        name: 'Date',
        keyName: 'created_at',
        type: 'date',
      },
    ],
  },
  userWisePoints: {
    details: {},
    keys: [
      {
        name: 'Total Points',
        keyName: 'total',
      },
      {
        name: 'UserId',
        keyName: '_id.user_id',
        type: 'nestedKey',
      },
    ],
  },
  userWisePointsPeerDetails: {
    details: {},
    keys: [
      {
        name: 'UserId',
        keyName: 'userId',
      },
      {
        name: 'Name',
        keyName: 'userName',
      },
      {
        name: 'User Points',
        keyName: 'userPoint',
      },
      {
        name: 'PeerId',
        keyName: 'peerId',
      },
      {
        name: 'Peer Name',
        keyName: 'peerName',
      },
      {
        name: 'Peer Points ',
        keyName: 'peerPoint',
      },
      {
        name: 'Total Points ',
        keyName: 'totalPoint',
      },

    ],
  },
  chatLogs: {
    details: {},
    keys: [
      {
        name: 'Date',
        keyName: 'last_message',
        type: 'date',
      },
      {
        name: 'Name',
        keyName: '_id.from_user_name',
        type: 'nestedKey',
      },
      {
        name: 'Mobile',
        keyName: '_id.from_user_id',
        type: 'nestedKey',
      },
      {
        name: 'Message Count',
        keyName: 'count',
      },
      {
        name: 'Peer Name',
        keyName: '_id.to_user_name',
        type: 'nestedKey',
      },

      {
        name: 'Peer Mobile',
        keyName: '_id.to_user_id',
        type: 'nestedKey',
      },
    ],
  },
  chatLogs_user_engagement: {
    details: {},
    keys: [
      {
        name: 'User Id',
        keyName: 'from_user_obj._id',
        type: 'nestedKey',
      },
      {
        name: 'User Name',
        keyName: 'from_user_obj.name',
        type: 'nestedKey',
      },
      {
        name: 'Group',
        keyName: 'from_user_obj.school',
        type: 'nestedKey',
      },
      {
        name: 'Date',
        keyName: 'created_at',
        type: 'date',
      },
      {
        name: 'Message Count',
        keyName: 'chat_count',
      },
      {
        name: 'Peer id',
        keyName: 'to_user_obj._id',
        type: 'nestedKey',
      },
      {
        name: 'Peer Name',
        keyName: 'to_user_obj.name',
        type: 'nestedKey',
      },
      {
        name: 'Peer Group',
        keyName: 'to_user_obj.school',
        type: 'nestedKey',
      },
    ],
  },
  user_points: {
    details: {},
    keys: [
      {
        name: 'Total Points',
        keyName: 'points',
      },
      {
        name: 'Audio Call Points',
        keyName: 'audio_points',
      },
      {
        name: 'Video Call Points',
        keyName: 'video_points',
      },
      {
        name: 'Chat Points',
        keyName: 'chat_points',
      },
    ],
  },
  pairedUsers: {
    details: {},
    keys: [
      {
        name: 'Username',
        keyName: 'username',
      },
      {
        name: 'Phone Number',
        keyName: 'phone_number',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Group',
        keyName: 'school',
      },
      {
        name: 'Peer',
        keyName: 'chat_points',
      },
      {
        name: 'Paire date',
        keyName: 'chat_points',
      },
      {
        name: 'Peer group',
        keyName: 'chat_points',
      },
    ],
  },
  unpairedUsers: {
    details: {},
    keys: [
      {
        name: 'Username',
        keyName: 'username',
      },
      {
        name: 'Phone Number',
        keyName: 'phone_number',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Group',
        keyName: 'school',
      },
      {
        name: 'Unpaired since',
        keyName: 'chat_points',
      },
    ],
  },
  usersAppDownloadsList: {
    details: {},
    keys: [
      {
        name: 'Username',
        keyName: 'username',
      },
      {
        name: 'Phone Number',
        keyName: 'phone_number',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Group',
        keyName: 'school',
      },
      {
        name: 'Download date',
        keyName: 'first_signup',
        type: 'date',
      },
    ],
  },
  registeredUsersList: {
    details: {},
    keys: [
      {
        name: 'Username',
        keyName: 'username',
      },
      {
        name: 'Phone Number',
        keyName: 'phone_number',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Group',
        keyName: 'school',
      },
      {
        name: 'Registered date',
        keyName: 'created_at',
        type: 'date',
      },
    ],
  },
  inductedUsersList: {
    details: {},
    keys: [
      {
        name: 'Username',
        keyName: 'username',
      },
      {
        name: 'Phone Number',
        keyName: 'phone_number',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Group',
        keyName: 'school',
      },
      {
        name: 'Induction date',
        type: 'nestedKey',
        keyName: 'activity_response.created_at',
      },
    ],
  },
  unpairedUsersList: {
    details: {},
    keys: [
      {
        name: 'Username',
        keyName: 'username',
      },
      {
        name: 'Phone Number',
        keyName: 'phone_number',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'DOB',
        keyName: 'dob',
      },
      {
        name: 'Gender',
        keyName: 'gender',
      },
      {
        name: 'Group',
        keyName: 'school',
      },
      {
        name: 'Unpaired since',
        keyName: 'unpaired_on',
        type: 'date',
      },
    ],
  },
  pairedUsersList: {
    details: {},
    keys: [
      {
        name: 'Username',
        keyName: 'username',
      },
      {
        name: 'Phone Number',
        keyName: 'phone_number',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Group',
        keyName: 'school',
      },
      {
        name: 'Peer Id',
        // keyName: "school",
      },
      {
        name: 'Peer Name',
        keyName: 'name',
        type: 'peerDetails',
      },
      {
        name: 'Peer Group',
        keyName: 'school',
        type: 'peerDetails',
      },
      {
        name: 'Paired on',
        keyName: 'paired_on',
        type: 'date',
      },
    ],
  },
  chatsListReport: {
    details: {},
    keys: [
      {
        name: 'Username',
        type: 'nestedKey',
        keyName: 'from_user_obj.username',
      },
      {
        name: 'Phone Number',
        type: 'nestedKey',
        keyName: 'from_user_obj.phone_number',
      },
      {
        name: 'Email',
        type: 'nestedKey',
        keyName: 'from_user_obj.email',
      },
      {
        name: 'Group',
        type: 'nestedKey',
        keyName: 'from_user_obj.school',
      },
      {
        name: 'Message count to peer',
        type: 'nestedKey',
        keyName: 'chat_count',
      },
      {
        name: 'Date',
        type: 'date',
        keyName: 'created_at',
      },
      {
        name: 'Peer Username',
        type: 'nestedKey',
        keyName: 'to_user_obj.username',
      },
      {
        name: 'Peer Phone Number',
        type: 'nestedKey',
        keyName: 'to_user_obj.phone_number',
      },

      {
        name: 'Peer Group',
        type: 'nestedKey',
        keyName: 'to_user_obj.school',
      },
    ],
  },
  voiceCallListReport: {
    details: {},
    keys: [
      {
        name: 'Username',
        type: 'nestedKey',
        keyName: 'from_user_obj.username',
      },
      {
        name: 'Phone Number',
        type: 'nestedKey',
        keyName: 'from_user_obj.phone_number',
      },
      {
        name: 'Email',
        type: 'nestedKey',
        keyName: 'from_user_obj.email',
      },
      {
        name: 'Group',
        type: 'nestedKey',
        keyName: 'from_user_obj.school',
      },
      {
        name: 'Duration',
        // keyName: 'duration_min',
        type: 'custom',
        Component: ({ data }) => {
          return (
            <TableCell component="th" scope="row">
              {data['duration_min']?.toFixed(2)}
            </TableCell>
          );
        },
      },
      {
        name: 'Number of calls',
        keyName: 'chat_count',
      },
      {
        name: 'Date',
        type: 'date',
        keyName: 'created_at',
      },
      {
        name: 'Peer Username',
        type: 'nestedKey',
        keyName: 'to_user_obj.username',
      },
      {
        name: 'Peer Phone Number',
        type: 'nestedKey',
        keyName: 'to_user_obj.phone_number',
      },

      {
        name: 'Peer Group',
        type: 'nestedKey',
        keyName: 'to_user_obj.school',
      },
    ],
  },
  videoCallListReport: {
    details: {},
    keys: [
      {
        name: 'Username',
        type: 'nestedKey',
        keyName: 'from_user_obj.username',
      },
      {
        name: 'Phone Number',
        type: 'nestedKey',
        keyName: 'from_user_obj.phone_number',
      },
      {
        name: 'Email',
        type: 'nestedKey',
        keyName: 'from_user_obj.email',
      },
      {
        name: 'Group',
        type: 'nestedKey',
        keyName: 'from_user_obj.school',
      },
      {
        name: 'Duration',
        type: 'custom',
        Component: ({ data }) => {
          return (
            <TableCell component="th" scope="row">
              {data['duration_min']?.toFixed(2)}
            </TableCell>
          );
        },
      },
      {
        name: 'Number of calls',
        keyName: 'chat_count',
      },
      {
        name: 'Date',
        type: 'date',
        keyName: 'created_at',
      },
      {
        name: 'Peer Username',
        type: 'nestedKey',
        keyName: 'to_user_obj.username',
      },
      {
        name: 'Peer Phone Number',
        type: 'nestedKey',
        keyName: 'to_user_obj.phone_number',
      },

      {
        name: 'Peer Group',
        type: 'nestedKey',
        keyName: 'to_user_obj.school',
      },
    ],
  },

  activePairedUsersReport: {
    details: {},
    keys: [
      {
        name: 'Username',
        keyName: 'username',
      },
      {
        name: 'Phone Number',
        keyName: 'phone_number',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Group',
        keyName: 'school',
      },
      {
        name: 'Registered date',
        keyName: 'created_at',
        type: 'date',
      },
    ],
  },
  activeUnPairedUsersReport: {
    details: {},
    keys: [
      {
        name: 'Username',
        keyName: 'username',
      },
      {
        name: 'Phone Number',
        keyName: 'phone_number',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Group',
        keyName: 'school',
      },
      {
        name: 'Registered date',
        keyName: 'created_at',
        type: 'date',
      },
    ],
  },

  inactivePairedUsersReport: {
    details: {},
    keys: [
      {
        name: 'Username',
        keyName: 'username',
      },
      {
        name: 'Phone Number',
        keyName: 'phone_number',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Group',
        keyName: 'school',
      },
      {
        name: 'Registered date',
        keyName: 'created_at',
        type: 'date',
      },
      {
        name: 'Inactive Since',
        keyName: 'last_active_time',
        type: 'date',
      },
    ],
  },
  inactiveUnPairedUsersReport: {
    details: {},
    keys: [
      {
        name: 'Username',
        keyName: 'username',
      },
      {
        name: 'Phone Number',
        keyName: 'phone_number',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Group',
        keyName: 'school',
      },
      {
        name: 'Registered date',
        keyName: 'created_at',
        type: 'date',
      },
      {
        name: 'Inactive Since',
        keyName: 'last_active_time',
        type: 'date',
      },
    ],
  },

  chatSupportMessagesList: {
    details: {},
    keys: [
      {
        name: 'Username',
        keyName: 'user_obj.username',
        type: 'nestedKey',
      },
      {
        name: 'Phone Number',
        keyName: 'user_obj.phone_number',
        type: 'nestedKey',
      },
      {
        name: 'Email',
        keyName: 'user_obj.email',
        type: 'nestedKey',
      },
      {
        name: 'Group',
        keyName: 'user_obj.school',
        type: 'nestedKey',
      },
      {
        name: 'Date',
        keyName: 'created_at',
        type: 'date',
      },
      {
        name: 'Issue Reported',
        keyName: 'message',
      },
    ],
  },

  contactUsMessagesList: {
    details: {},
    keys: [
      {
        name: 'Username',
        keyName: 'user_obj.username',
        type: 'nestedKey',
      },
      {
        name: 'Phone Number',
        keyName: 'phone_number',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Group',
        keyName: 'user_obj.school',
        type: 'nestedKey',
      },
      {
        name: 'Date',
        keyName: 'created_at',
        type: 'date',
      },
      {
        name: 'Issue reported',
        keyName: 'message',
      },
    ],
  },

  activityResponsesReport: {
    details: {},
    keys: [
      {
        name: 'Username',
        keyName: 'user_obj.username',
        type: 'nestedKey',
      },
      {
        name: 'Phone Number',
        keyName: 'user_obj.phone_number',
        type: 'nestedKey',
      },
      {
        name: 'Email',
        keyName: 'user_obj.email',
        type: 'nestedKey',
      },
      {
        name: 'Group',
        keyName: 'user_obj.school',
        type: 'nestedKey',
      },
      {
        name: 'Activity name',
        keyName: 'program_obj.title',
        type: 'nestedKey',
      },
      {
        name: 'Task title',
        keyName: 'activity_obj.title',
        type: 'nestedKey',
      },
      {
        name: 'Date',
        keyName: 'created_at',
        type: 'date',
      },
      {
        name: 'Response',
        keyName: 'response',
        type: 'stringifyJson',
      },
    ],
  },
  activityCompletionStatusReport: {
    details: {},
    keys: [
      {
        name: 'Name',
        keyName: 'title',
      },
      {
        name: 'Activity Type',
        keyName: 'public',
        type: 'custom',
        Component: ({ data }) => {
          return (
            <TableCell component="th" scope="row">
              {data['public'] ? 'Individual' : 'Peer'}
            </TableCell>
          );
        },
      },
      {
        name: 'Status',
        keyName: 'status',
      },
      {
        name: 'Count',
        keyName: 'count',
        type: 'custom',
        Component: ({ data }) => {
          let school = store.getState()?.commonReducer?.filters?.selectedGroup;
          return (
            <TableCell component="th" scope="row">
              <Link
                to={{
                  pathname: `/reports/activities/userActivityCompletionStatusReport`,

                  state: {
                    apiFunctionName: 'userActivityCompletionStatusReport',
                    apiParams: `&program_id=${data?._id?._id}&status=${data?.status
                      }&school=${school === 'All' ? '' : school}`,
                  },
                }}
              >
                {data['count']}
              </Link>
            </TableCell>
          );
        },
      },
    ],
  },
  userActivityCompletionStatusReport: {
    details: {},
    keys: [
      {
        name: 'Title',
        keyName: 'title',
      },
      {
        name: 'Activity Type',
        keyName: 'public',
        type: 'custom',
        Component: ({ data }) => {
          return (
            <TableCell component="th" scope="row">
              {data['public'] ? 'Individual' : 'Peer'}
            </TableCell>
          );
        },
      },
      {
        name: 'Status',
        keyName: 'status',
      },
      {
        name: 'Last Modified Date',
        keyName: 'last_activity_date',
        type: 'date',
      },
      {
        name: 'Username',
        keyName: 'user_obj.username',
        type: 'nestedKey',
      },
      {
        name: 'Phone Number',
        keyName: 'user_obj.phone_number',
        type: 'nestedKey',
      },
      {
        name: 'Email',
        keyName: 'user_obj.email',
        type: 'nestedKey',
      },
      {
        name: 'Group',
        keyName: 'user_obj.school',
        type: 'nestedKey',
      },
    ],
  },
  pointsReport: {
    details: {},
    keys: [
      {
        name: 'Username',
        keyName: 'user_obj.username',
        type: 'nestedKey',
      },
      {
        name: 'Phone Number',
        keyName: 'user_obj.phone_number',
        type: 'nestedKey',
      },
      {
        name: 'Email',
        keyName: 'user_obj.email',
        type: 'nestedKey',
      },
      {
        name: 'Group',
        keyName: 'user_obj.school',
        type: 'nestedKey',
      },

      {
        name: 'Category',
        keyName: 'type',
        defaultValue: 'ACTIVITY',
      },
      {
        name: 'Points',
        keyName: 'points',
      },
      {
        name: 'Date',
        keyName: 'created_at',
        type: 'date',
      },
      {
        name: 'Additional information',
        type: 'custom',
        Component: ({ data }) => {
          const { program_obj, meta, title, type } = data;

          if (type === 'VIDEOCALL' || type === 'VOICECALL' || type === 'CHAT' || type === 'PAIRED_SUCCESFULLY') {
            return (
              <TableCell component="th" scope="row">
                -
              </TableCell>
            );
          }
          if (type === 'BONUS' || type === 'WEBINAR' || type === 'VIDEO') {
            return (
              <TableCell component="th" scope="row">
                {title}
              </TableCell>
            );
          }
          if (type === 'QUESTION') {
            return (
              <TableCell component="th" scope="row">
                {title}
              </TableCell>
            );
          }

          return (
            <TableCell component="th" scope="row">
              Activity name: {program_obj?.title} {'\n'}
              Task name: {meta?.title}
            </TableCell>
          );
        },
      },
    ],
  },
  pairingHistory: {
    details: {},
    keys: [
      {
        name: 'User Id',
        keyName: 'user_id',
        type: 'user',
      },
      {
        name: 'Buddy Id',
        keyName: 'buddy_id',
        type: 'user',
      },
      {
        name: 'Unpairing Reason',
        keyName: 'unpair_reason',
      },
      {
        name: 'Paired on',
        keyName: 'paired_on',
        type: 'date',
      },
      {
        name: 'Unpaired on',
        keyName: 'unpaired_on',
        type: 'date',
      },
    ],
  },
  webinar_points: {
    keys: [
      {
        name: 'Title',
        keyName: 'title',
      },
      {
        name: 'Reference Id',
        keyName: 'reference_id',
      },
      {
        name: 'Points',
        keyName: 'points',
      },
      {
        name: 'Description',
        keyName: 'description',
      },
      {
        name: 'Date',
        keyName: 'created_at',
        type: 'date',
      },
    ],
  },
  question_points: {
    keys: [
      {
        name: 'Title',
        keyName: 'title',
      },
      {
        name: 'Points',
        keyName: 'points',
      },
      {
        name: 'Date',
        keyName: 'created_at',
        type: 'date',
      },
    ],
  },
  pollQuestions: {
    details: {
      navigateTo: '/qna-responses',
    },
    keys: [
      {
        name: 'Question ID',
        keyName: 'meta._id',
        type: 'nestedKey',
      },
      {
        name: 'Header',
        keyName: 'meta.header',
        type: 'nestedKey',
      },
      {
        name: 'Title',
        keyName: 'meta.title',
        type: 'nestedKey',
      },
      {
        name: 'Description',
        keyName: 'meta.footer',
        type: 'nestedKey',
      },
      {
        name: 'Is Deleted',
        keyName: 'deleted',
        type: 'bool',
      },
    ],
  },
  complaintsAgainstUser: {
    keys: [
      {
        name: 'Name',
        keyName: 'name',
      },
      {
        name: 'UserId',
        keyName: 'phone_number',
      },
      {
        name: 'Email',
        keyName: 'email',
      },
      {
        name: 'Complaint Against UserId',
        keyName: 'meta.peerId',
        type: 'nestedKey',
      },
      {
        name: 'Message',
        keyName: 'message',
      },
      {
        name: 'Date',
        keyName: 'created_at',
        type: 'date',
      },
    ],
  },
  discountCouponsList: {
    details: {
      navigateTo: '/discount-coupons/edit',
      navigateToCTA: 'Edit',
    },
    keys: [
      {
        name: 'Name',
        keyName: 'discount_name',
      },
      {
        name: 'Code',
        keyName: 'discount_code',
      },
      // {
      //   name: 'Type',
      //   keyName: 'type',
      // },
      {
        name: 'Discount Percentage',
        keyName: 'amount',
      },
      {
        name: 'Active',
        keyName: 'is_active',
        type: 'bool',
      },
      {
        name: 'Date',
        keyName: 'created_at',
        type: 'date',
      },
    ],
  },
};

export default fieldNames;
